import { React } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { TelephoneFill, GeoAltFill, EnvelopeFill } from "react-bootstrap-icons"

import "./../css/custom.css"

const Footer = () => {
  return (
    <Container>
      <Row className="footer-format">
        <Col sm>{contacts()}</Col>
        <Col sm>{googleMap()}</Col>
      </Row>
    </Container>
  )
}

const contacts = () => {
  return (
    <Container className="footer-text">
      <Row className="footer-spacing">
        <Col>
          <strong>Everest Eye Threading Salon</strong>
        </Col>
      </Row>
      <Row className="footer-spacing">
        <Col sm={0.02}>
          <TelephoneFill className="footer-icon" color="white" size={15} />
        </Col>
        <Col>
          <a href="tel:3033711345">(303) 371-1345</a>
        </Col>
      </Row>
      <Row className="footer-spacing">
        <Col sm={0.2}>
          <EnvelopeFill color="white" size={15} />
        </Col>
        <Col>
          <a href="mailto:denverthreading@gmail.com">denverthreading@gmail.com</a>
        </Col>
      </Row>
      <Row className="footer-spacing">
        <Col sm={0.2}>
          <GeoAltFill color="white" size={15} />
        </Col>
        <Col>8354 Northfield Blvd, Denver, CO 80238, United States</Col>
      </Row>
      <div className="footer-line"></div>
    </Container>
  )
}

const googleMap = () => {
  return (
    <div className="map-container-2">
      <iframe
        title="test"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3066.0904368824213!2d-104.89404138343406!3d39.78252577944393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c7b07effed7f5%3A0xb18f9d61faa994e0!2sEyebrow%20Threading%20and%20Design!5e0!3m2!1sen!2sca!4v1609009949841!5m2!1sen!2sca"
        width="600"
        height="450"
        frameBorder="1"
        style={{ border: 5 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  )
}

export default Footer
